import React, {useContext} from 'react';
import { Routes, Route } from 'react-router-dom';
import { UserContext } from '../user-context/UserContext';
import HomePage from '../pages/HomePage';
import SaveTheDatePage from '../pages/SaveTheDatePage';
import LoginPage from '../pages/LoginPage';
import RSVPsPage from '../pages/RSVPsPage';



const PublicRoutes = () => {
    const {user} = useContext(UserContext);
    return (
        <Routes>
            <Route exact={true} path="/" element={<HomePage />} />
            {user?.uid && <Route exact={true} path="/save-the-date" element={<SaveTheDatePage />} />}
            {user?.uid && <Route exact={true} path="/rsvps" element={<RSVPsPage />} />}
            <Route exact={true} path="/login" element={<LoginPage />} />
            <Route exact={true} path="*"
                element={<HomePage />}
            />
        </Routes>
    );
}

export default PublicRoutes;
