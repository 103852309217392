import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { UserContext } from '../user-context/UserContext';

import './LoginPage.scss';

const LoginPage = () => {
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        if (email === '' || password === '') {
            setSubmitted(false);
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 500);
            return;
        }
        const service = new AuthService();
        const response = await service.signIn(email, password);
        if (response) {
            setUser(response);
            setSubmitted(true);
            navigate("/");
            return;
        }
        setSubmitted(false);
        setError(true);
        setTimeout(() => {
            setError(false);
        }, 500);

    }

    return (
        <div className='login-container'>
            {/* Add your page content here */}
            <div className='container'>
                <div className='row'>
                    <div className='first-section col-12 divider-x'>
                        <div className='inner'>
                            <div className='heading-text hurricane-regular black'>
                                C + R
                            </div>
                        </div>
                    </div>

                    <div className='seventh-section divider-x col-12'>
                        <div className='inner'>
                            <div className='heading text-center hurricane-regular black px-4'>
                                Login
                            </div>
                            <div className='row justify-content-center'>
                                <div className='col-sm-12 col-md-12 col-lg-8 col-12'>
                                    <form className={`'form mt-3' ${error ? 'shake' : ""}`}>
                                        {!submitted && <Fragment>
                                            <div className='form-floating'>
                                                <input type='email' className='form-control' placeholder='email' id='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                                <label htmlFor="email" className='form-label hiragino-regular light' >Email</label>
                                            </div>
                                            <div className='form-floating'>
                                                <input type='password' className='form-control' placeholder='Password' id='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                                <label htmlFor='password' className='form-label hiragino-regular light'>Password</label>
                                            </div>
                                            <div className='form-button d-flex justify-content-between'>
                                                <div className='btn btn-secondary' onClick={() => navigate("/")}>Cancel</div>
                                                <div className={`btn btn-primary ${error ? '' : ''}`} onClick={(e) => handleLogin(e)}>Login</div>
                                            </div>
                                        </Fragment>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;