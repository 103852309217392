import React, { Fragment, useState, useEffect, useRef } from 'react';
import './HomePage.scss';

import Countdown from '../components/countdown/Countdown';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import RSVPService from '../services/RSVPService'

const HomePage = () => {
    const ref01 = useRef(null)
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [attending, setAttending] = useState(null);
    const [song, setSong] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [colors] = useState([
        "#000000", "#342E33", "#162A2C", "#504F4C", "#383b26", "#80744A", "#885E43", "#CFA47E", "#B29A89", "#8f907e"
    ]);

    const onSubmit = async () => {
        setLoading(true);
        if (name === '' || surname === '' || attending === null) {
            setError(true);
            setTimeout(() => {
                setError(false);
                setLoading(false);
            }, 500);

            return;
        }
        const model = {
            name: name,
            surname: surname,
            attending: attending === "yes" ? true : false,
            song: song
        }
        const service = new RSVPService();
        const response = await service.createRSVP(model);
        if (response) {
            setSubmitted(true);
            setLoading(false);
            return;
        }
        setError(true);
        setTimeout(() => {
            setError(false);
            setLoading(false);
        }, 500);
        return;
    }

    useEffect(() => {
        if (submitted){
            setTimeout(() => {
                ref01?.current?.scrollIntoView({block: 'center', behavior: 'smooth'});
            }, 30);
        }
    },[submitted]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
    }, []);

    return (
        <div className='home-container'>
            {/* Add your page content here */}
            <div className='container'>
                <div className='row'>
                    <div className='first-section col-12 divider-x' >
                        <div className='inner'>
                            <div className='heading-text hurricane-regular black'>
                                &nbsp;
                            </div>
                        </div>
                    </div>

                    <div className={`second-section col-12 divider-x`}>
                        <div className='inner'>
                            <div className='heading hurricane-regular black text-center'>
                                <div className='d-flex  justify-content-center'>
                                    <div>Chené&nbsp;&nbsp;</div><div className='light plus-sign'>+</div> <div>&nbsp;Rynand</div>
                                </div>

                            </div>
                            <div className='sub-heading hiragino-regular light'>
                                <p>We invite you to celebrate our wedding</p>
                            </div>
                        </div>
                    </div>

                    <div className='third-section col-12 divider-x'>
                        <div className='inner'>
                            <div className='row'>
                                <div className='col-12 col-md-4 text-center vertical-middle '>
                                    <div>
                                        <div className='heading hiragino-regular light'>
                                            Saturday
                                        </div>
                                        <div className='number hiragino-regular bold'>
                                            23
                                        </div>
                                        <div className='sub-heading hiragino-regular bold'>
                                            November
                                        </div>
                                        <div className='sub-heading hiragino-regular bold'>
                                            2024
                                        </div>
                                    </div>

                                </div>
                                <div className='col-12 mobile-divider-x'><div></div></div>
                                <div className='col-12 col-md-8 divider-y vertical-middle'>
                                    <div className='text text-center hiragino-regular light'>
                                        <p>Join us on our special day.</p>
                                        <p>Guests to arrive from <strong className='bold'>15:00</strong>. <br />Ceremony will start at <strong className='bold'>15:30</strong>.</p>
                                        <p className='mb-0'>Dinner and dancing to follow.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='fourth-section col-12 divider-x'>
                        <div className='inner'>
                            <div className='row'>
                                <div className='col-12 col-lg-6 col-md-8 vertical-middle order-3 order-md-1'>
                                    <div>
                                        <div className='heading hiragino-regular bold'>
                                            <p>Our dress code</p>
                                        </div>
                                        <div className='heading hiragino-regular light'>
                                            <p>The dress code is <strong className='bold'>semi-formal</strong> and we encourage our guests to wear <strong className='bold'>neutral colours</strong> on our special day.</p>
                                        </div>
                                        <div className='heading hiragino-regular light px-4 mt-3 d-flex flex-wrap justify-content-center'>
                                            {colors.map((color, index) => (
                                                <div className='color-icon  hover-shake' key={index} style={{ backgroundColor: color }}></div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mobile-divider-x order-2'><div></div></div>
                                <div className='col-12 col-lg-6 col-md-4 divider-y text-center order-1 order-md-3'>
                                    <div>
                                        <div className='heading hiragino-regular bold'>
                                            <p>Cipresso Venue</p>
                                        </div>
                                        <div className='text text-center hiragino-regular light px-4'>
                                            <p>1 First Street, Bashewa,<br /> Pretoria, 0056</p>
                                        </div>
                                        <div className='text text-center hiragino-regular light'>
                                            <p>Please <strong className='bold'>RSVP</strong> by<br /><strong className='bold'>23 September 2024</strong>.</p>
                                        </div>
                                        <div className='text text-center hiragino-regular light px-4 mb-0'>
                                            <p className='mb-0'>We look forward to celebrating with you.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='fifth-section divider-x col-12'>
                        <div className='inner'>
                            <div className='heading text-center hurricane-regular light px-4'>
                                RSVP
                            </div>
                            <div className='row justify-content-center'>
                                <div className='col-sm-12 col-md-12 col-lg-8 col-12'>
                                    <form className={`'form mt-3' ${error ? 'shake' : ""}`}>
                                        {!submitted ? <Fragment>
                                            <div className='form-floating'>
                                                <input type='text' className='form-control' placeholder='Name' id='name' value={name} onChange={(e) => setName(e.target.value)} />
                                                <label htmlFor="name" className='form-label hiragino-regular light' >Name</label>
                                            </div>
                                            <div className='form-floating'>
                                                <input type='text' className='form-control' placeholder='Surname' id='surname' value={surname} onChange={(e) => setSurname(e.target.value)} />
                                                <label htmlFor='surname' className='form-label hiragino-regular light'>Surname</label>
                                            </div>
                                            <div className='form-tabs d-flex'>
                                                <div className={`form-tab hiragino-regular light ${attending === "yes" ? "active" : ""}`} onClick={() => setAttending("yes")}>
                                                    Happily <FavoriteIcon /> Attending
                                                </div>
                                                <div className={`form-tab hiragino-regular light ${attending === "no" ? "active" : ""}`} onClick={() => setAttending("no")}>
                                                    Regretfully <HeartBrokenIcon /> Declining
                                                </div>
                                            </div>
                                            <div className='form-floating'>
                                                <textarea className='form-control' placeholder='Song request' id='message' rows='3' value={song} onChange={(e) => setSong(e.target.value)}></textarea>
                                                <label htmlFor='song' className='form-label hiragino-regular light'>Song request</label>
                                            </div>


                                            <div className='form-button'>
                                                <div className={`btn btn-primary ${error ? '' : ''}`} onClick={() => !loading && onSubmit()}>Submit</div>
                                            </div>
                                        </Fragment>
                                            : <Fragment>
                                                <div ref={ref01} className='form-thanks hiragino-regular italic light'>
                                                    {attending === "yes" ? "Thank to for your RSVP. We look forward to celebrating with you." : "We will miss you."}
                                                </div>
                                            </Fragment>}
                                    </form>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='sixth-section divider-x col-12'>
                        <div className='inner'>
                            <div className='row'>
                                <div className='col-12 col-md-6 text-center vertical-middle'>
                                    <div>
                                        <div className='heading text-center hiragino-regular bold px-4'>
                                            <p>Accommodation</p>
                                        </div>
                                        <div className='text text-center hiragino-regular light px-4'>
                                            <p>The following accommodation is available nearby the venue.</p>
                                            <p><LocationOnRoundedIcon /></p>
                                            <p className='mb-0'>Wattle Katels - 3.3 km<br />
                                                Three Thymes Inn - 7.2 km<br />
                                                The Highveld Cape Inn - 7.2 km<br />
                                                Ingaadi - 8.1 km</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mobile-divider-x'><div></div></div>
                                <div className='col-12 col-md-6 text-center vertical-middle divider-y'>
                                    <div>
                                        <div className='heading text-center hiragino-regular bold px-4'>
                                            <p> A note on gifts</p>
                                        </div>
                                        <div className='text text-center hiragino-regular light px-4'>
                                            <p className='mb'>Your presence at our wedding is the greatest gift of all. However, if you wish to honour us with a gift, a contribution to our honeymoon fund would be very welcome.</p>
                                            <div className=''>
                                                <p>Bank: Standard Bank<br/>
                                                    Acc Name: R Wessels<br/>
                                                    Acc No: 01 273 992 8</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-12'>
                        <div className='inner'>
                            <div className='row'>
                                <div className='col-12 col-md-1'>
                                </div>
                                <div className='col-12 col-md-10 text-center'>
                                    <Countdown />
                                </div>
                                <div className='col-12 col-md-1'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;