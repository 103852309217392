import React, { useEffect } from 'react';
import './SaveTheDatePage.scss';

const SaveTheDatePage = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
    }, []);

    return (
        <div className='save-the-date-page'>
            {/* Add your page content here */}
            <div className='center-text hurricane-regular'>finally.</div>
            <div className='side-text hiragino-regular light'>Chené & Rynand</div>
            <div className='heading-text hiragino-regular light'>
            SAVE THE <span className='black'>DATE</span> FOR <span className='regular'>CHENÉ & RYNAND'S</span> WEDDING<br/>
            <span className='regular'>23  NOVEMBER  2024</span> &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp; PRETORIA, BASHEWA AH
            </div>
            <div className='sub-heading-text hiragino-regular light'>Due to the intimate nature of the wedding please let us know if you can't join.</div>
        </div>
    );
};

export default SaveTheDatePage;