import React, { useState, useEffect, Fragment } from 'react';
import './Countdown.scss';

const Countdown = () => {
    const [countdown, setCountdown] = useState(null);

    useEffect(() => {
        // Set the expiry date
        const expiryDate = new Date('2024-11-23T15:00:00');

        // Update the countdown every second
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = expiryDate - now;

            // Calculate days, hours, minutes, and seconds
            const months = Math.floor(distance / (1000 * 60 * 60 * 24 * 30));
            const days = Math.floor((distance % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Format the countdown string
            //const countdownString = `${months}m ${days}d ${hours}h ${minutes}m ${seconds}s`;
            const countdownObject = {
                months: months,
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds
            };

            // Update the countdown state
            setCountdown(countdownObject);

            // If the countdown is finished, clear the interval
            if (distance < 0) {
                clearInterval(interval);
                setCountdown('Expired');
            }
        }, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <div className='countdown-component'>
            {countdown &&
                <Fragment>
                    {countdown === 'Expired' &&
                        <div className='hurricane-regular its-time'>finally.</div>}
                    <div className='timer-container'>
                        <div className='timer-block'>
                            <div className='hiragino-regular time'>
                                {countdown?.months || 0}
                            </div>
                            <div className='hiragino-regular light description'>
                                Month{countdown?.months === 1 ? '' : 's'}
                            </div>
                        </div>
                        <div className='count-dot'>.</div>
                        <div className='timer-block'>
                            <div className='hiragino-regular time'>
                                {countdown?.days || 0}
                            </div>
                            <div className='hiragino-regular light description'>
                                Day{countdown?.days === 1 ? '' : 's'}
                            </div>
                        </div>
                        <div className='count-dot'>.</div>
                        <div className='timer-block'>
                            <div className='hiragino-regular time'>
                                {countdown?.hours || 0}
                            </div>
                            <div className='hiragino-regular light description'>
                                Hour{countdown?.hours === 1 ? '' : 's'}
                            </div>
                        </div>
                        <div className='count-dot'>.</div>
                        <div className='timer-block'>
                            <div className='hiragino-regular time'>
                                {countdown?.minutes || 0}
                            </div>
                            <div className='hiragino-regular light description'>
                                Minute{countdown?.minutes === 1 ? '' : 's'}
                            </div>
                        </div>
                        <div className='count-dot'>.</div>
                        <div className='timer-block'>
                            <div className='hiragino-regular time'>
                                {countdown?.seconds || 0}
                            </div>
                            <div className='hiragino-regular light description'>
                                Second{countdown?.seconds === 1 ? '' : 's'}
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </div>
    );
};

export default Countdown;