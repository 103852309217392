import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.scss';
import { Link } from 'react-router-dom';
import { UserContext } from '../../user-context/UserContext';
import { signOut } from 'firebase/auth';
import { auth } from '../../config/FirebaseConfig';

const Footer = () => {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        signOut(auth).then(() => {
            setUser({})
            navigate("/");
        });
    }

    return (
        <div className='footer-component divider-x-top'>
            <div className='container text-center'>
                <div className='title hurricane-regular black'>
                    C + R
                </div>
                <div className='d-flex justify-content-center'>
                    <div className='links d-flex justify-content-around hiragino-regular'>
                        {user?.uid && <Link to="/">Home</Link>}
                        {user?.uid && <Link to="/save-the-date">Save the Date</Link>}
                        {!user?.uid && <Link to="/login">Login</Link>}
                        {user?.uid && <Link to="/rsvps">RSVPs</Link>}
                        {user?.uid && <Link onClick={() => handleLogout()} >Logout</Link>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;