import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../user-context/UserContext';
import RSVPService from '../services/RSVPService'
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './RSVPsPage.scss';

const RSVPsPage = () => {
    const { user } = useContext(UserContext);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        const service = new RSVPService();
        const response = await service.getRSVP();
        response.sort((a, b) => a.attending < b.attending ? 1 : -1);
        setData(response);
        setLoading(false);
    }

    return (
        <div className='rsvps-container'>
            {/* Add your page content here */}
            <div className='container'>
                <div className='row'>
                    <div className='first-section col-12 divider-x'>
                        <div className='inner'>
                            <div className='heading-text hurricane-regular black'>
                                C + R
                            </div>
                        </div>
                    </div>
                    <div className='seventh-section divider-x col-12'>
                        <div className='inner'>
                            <div className='text text-center hiragino-regular light px-4'>
                                {user?.email}
                            </div>
                            <div className='heading text-center hurricane-regular black px-4'>
                                RSVP List
                            </div>
                            {!loading &&

                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650, overflowX: 'auto' }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{fontWeight: '900'}}>#</TableCell>
                                                <TableCell sx={{fontWeight: '900'}}>Name</TableCell>
                                                <TableCell sx={{fontWeight: '900'}}>Surname</TableCell>
                                                <TableCell sx={{fontWeight: '900'}}>Attending</TableCell>
                                                <TableCell sx={{fontWeight: '900'}}>Song Request</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.map((item, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" sx={{fontWeight: '100'}}>
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell sx={{fontWeight: '100'}}>{item.name}</TableCell>
                                                    <TableCell sx={{fontWeight: '100'}}>{item.surname}</TableCell>
                                                    <TableCell style={{ color: item?.attending ? '#C21E56' : '#000' }}>{item.attending ? <FavoriteIcon /> : <HeartBrokenIcon />}</TableCell>
                                                    <TableCell sx={{fontWeight: '100'}}>{item.song}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RSVPsPage;