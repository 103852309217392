import './App.scss';
import PublicRoutes from './routes/PublicRoutes';
import Footer from './components/footer/Footer';

function App() {
  return (
    <div className="app container-fluid">
      <PublicRoutes />
      <Footer />
    </div>
  );
}

export default App;
