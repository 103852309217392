import { collection, getDocs, getDoc, doc, addDoc /*query, where*/ } from 'firebase/firestore';
import { db } from '../config/FirebaseConfig';
//import { getAuth } from 'firebase/auth';

class ListingService {
    getRSVP = async () => {
        try {
            const snapshot = await getDocs(collection(db, 'rsvp'));
            const rsvpList = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id}));
            return rsvpList;
        } catch (error) {
            return [];
        }
    }

    getRSVPById = async (id) => {
        try {
            const snapshot = await getDoc(doc(db, "rsvp", id));
            return { ...snapshot.data(), id: snapshot.id };
        } catch (error) {
            return {};
        }
    }

    createRSVP = async (rsvp) => {
        console.log(rsvp);
        try {
            await addDoc(collection(db, 'rsvp'), rsvp);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
}

export default ListingService;